import React from 'react';
import { IApiService, PagingRequest } from './base';
import dayjs from 'dayjs';
import { IApiListResponse } from './policies/new/service';
import {
  CreateInvoiceRequest,
  CreateInvoiceResponse,
  IInvoiceItem,
  IInvoiceItemV2,
  ProductDataForInvoice,
  ProductInvoiceResponse,
} from './billing/interfaces';
import {
  ProductInvoiceRequest,
  ListPolicyInvoicesRequest,
  ListInvoices,
  GetInvoice,
  InvoiceRequestExpandType,
  CreateInvoice,
} from './billing/requests';

export interface IBillingService {
  getPolicyInvoices( policyCode: string, paging: PagingRequest ): Promise<IApiListResponse<IInvoiceItem>>;
  listInvoices( searchValue: string, paging: PagingRequest ): Promise<IApiListResponse<IInvoiceItemV2>>;
  getInvoice( code: string, expands: InvoiceRequestExpandType[] ): Promise<IInvoiceItem>;
  getProductInvoice( productCode: string, data: ProductDataForInvoice ): Promise<ProductInvoiceResponse>;
  createInvoice( payload: CreateInvoiceRequest ): Promise<CreateInvoiceResponse>;
}

export class BillingService implements IBillingService {
  protected api: IApiService;
  constructor( api: IApiService ) {
    this.api = api;
  }

  getPolicyInvoices( policyCode: string, paging: PagingRequest ) : Promise<IApiListResponse<IInvoiceItem>> {
    return this.api.request( new ListPolicyInvoicesRequest( paging, policyCode ) )
      .then( ( response ) => {
        let invoiceData : IInvoiceItem[] = [];
        const { items, nextPageToken } = response;

        if ( items ) {
          invoiceData = items.map( ( item, index ) => {
            const InvoiceItem: IInvoiceItem = {
              ...item,
              createdAt: dayjs( item.createdAt ),
              dueDate: dayjs( item.dueDate ),
              billingIntervalFrom: dayjs( item.billingIntervalFrom ),
              billingIntervalTo: dayjs( item.billingIntervalTo ),
            };
            return InvoiceItem;
          } );
        }
        const res: IApiListResponse<IInvoiceItem> = {
          items: invoiceData,
          nextPageToken: invoiceData ? invoiceData.length < paging.pageSize ? '1' : nextPageToken : nextPageToken,
        };

        return res;
      } );
  }

  listInvoices ( searchValue: string, paging: PagingRequest ): Promise<IApiListResponse<IInvoiceItemV2>> {
    return this.api.request( new ListInvoices( searchValue, paging ) )
      .then( ( response ) => {
        const { items, nextPageToken } = response;

        const res: IApiListResponse<IInvoiceItemV2> = {
          items: items,
          nextPageToken: items ? items.length < paging.pageSize ? '1' : nextPageToken : nextPageToken,
        };

        return res;
      } );
  }


  async getProductInvoice( productCode: string, payload: ProductDataForInvoice ): Promise<ProductInvoiceResponse> {
    try {
      const response = await this.api.request( new ProductInvoiceRequest( productCode, payload ) );

      return response;
    } catch( e ) {
      throw new Error( 'There has been an error on product invoice' );
    }
  }

  async getInvoice( code: string, expand: InvoiceRequestExpandType[] ): Promise<IInvoiceItem> {
    return this.api.request( new GetInvoice( code, expand ) )
      .then( ( response ) => {
        const { invoice } = response;
        let res: IInvoiceItem;

        res = {
          ...invoice,
          billingIntervalFrom: dayjs( invoice.billingIntervalFrom ),
          billingIntervalTo: dayjs( invoice.billingIntervalTo ),
          createdAt: dayjs( invoice.createdAt ),
          dueDate: dayjs( invoice.dueDate ),
        };

        return res;
      } );
  }

  async createInvoice( payload: CreateInvoiceRequest ): Promise<CreateInvoiceResponse> {
    const response = await this.api.request( new CreateInvoice( payload ) );

    return response;
  }
};

export const BillingServiceContext: React.Context<IBillingService> = React.createContext( undefined as any );

export const useBillingService = (): IBillingService => {
  return React.useContext( BillingServiceContext );
};
