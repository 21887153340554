import { BaseRequest, PagingRequest } from 'Services/base';
import { IApiListResponse } from 'Services/policies/new/service';
import { getQueryParamsForListAPI } from '../api';
import {
  ApiInvoiceItem,
  CreateInvoiceRequest,
  CreateInvoiceResponse,
  GetInvoiceResponse,
  ProductDataForInvoice,
  ProductInvoiceResponse,
} from './interfaces';

const billingServiceApiServer = process.env.REACT_APP_BILLING_SERVICE_API_SERVER ?? '';
const billingService = `${billingServiceApiServer}/billingservice`;

export type InvoiceRequestExpandType = 'currency' | 'statuses' | 'items' | 'payments';

export class ListPolicyInvoicesRequest extends BaseRequest<IApiListResponse<ApiInvoiceItem>> {
  constructor(
    paging: PagingRequest,
    policyCode: string,
    expand: InvoiceRequestExpandType[] = [ 'currency' ],
  ) {
    let expandPars = '';
    if ( expand.length > 0 ) {
      expandPars = expand.map( ( item ) => `expand=${encodeURIComponent( item )}` ).join( '&' );
    }

    super( {
      method: 'GET',
      url: `${billingService}/v1/invoices?${expandPars}`,
      responseType: 'json',
      params: getQueryParamsForListAPI( {
        ...paging,
        filter: [ { id: 'policyCode', value: policyCode }, ...paging.filter ],
      } ),
    } );
  }
}

export class ListInvoices extends BaseRequest<IApiListResponse<ApiInvoiceItem>> {
  constructor(
    searchValue: string,
    paging: PagingRequest,
    expand: InvoiceRequestExpandType[] = [ 'currency' ],
  ) {
    let expandPars = '';
    if ( expand.length > 0 ) {
      expandPars = expand.map( ( item ) => `expand=${encodeURIComponent( item )}` ).join( '&' );
    }

    super( {
      method: 'GET',
      url: `${billingService}/v1/invoices?${expandPars}`,
      responseType: 'json',
      params: getQueryParamsForListAPI( {
        ...paging,
      }, searchValue, 'search' ),
    } );
  }
}

export class GetInvoice extends BaseRequest<GetInvoiceResponse> {
  constructor(
    code: string,
    expand: InvoiceRequestExpandType[] = [ ],
  ) {
    let expandPars = '';
    if ( expand.length > 0 ) {
      expandPars = expand.map( ( item ) => `expand=${encodeURIComponent( item )}` ).join( '&' );
    }

    super( {
      method: 'GET',
      url: `${billingService}/v1/invoices/${code}?${expandPars}`,
      responseType: 'json',
    } );
  }
}

export class CreateInvoice extends BaseRequest<CreateInvoiceResponse> {
  constructor(
    payload: CreateInvoiceRequest,
  ) {
    super( {
      method: 'POST',
      url: `${billingService}/v1/invoices/`,
      responseType: 'json',
      data: payload,
    } );
  }
}

export class ProductInvoiceRequest extends BaseRequest<ProductInvoiceResponse> {
  constructor( productCode: string, payload: ProductDataForInvoice ) {
    super( {
      method: 'POST',
      url: `/publicapi/v1/products/${ productCode }/product-invoice`,
      data: payload,
      responseType: 'json',
    } );
  }
}
